<script setup lang="ts">
import { ForecastReportType } from '@reports/constants'
import type { DateRangeResponse } from '~/data-services/gen'

type DatePickerModel = {
  month?: number
  year?: number
}

type Props = {
  drawerWidth?: string
  isModalOpen?: boolean
  forecastViewDateRange?: DateRangeResponse
  type?: ForecastReportType
}

const props = defineProps<Props>()

const modelValue = defineModel<{
  from?: DatePickerModel
  to?: DatePickerModel
}>('dateRange')

const yearEnding = defineModel<string | undefined>('yearEnding')

const dayjs = useDayjs()

const initialFrom = computed(() => {
  const from = props.forecastViewDateRange?.from
  if (!from) return
  const month = dayjs(props.forecastViewDateRange?.from).month()
  const year = dayjs(props.forecastViewDateRange?.from).year()
  return { month, year }
})

const initialTo = computed(() => {
  const to = props.forecastViewDateRange?.to
  if (!to) return
  const month = dayjs(props.forecastViewDateRange?.to).month()
  const year = dayjs(props.forecastViewDateRange?.to).year()
  return { month, year }
})

const dateModelFrom = ref<Date | undefined>()
const dateModelTo = ref<Date | undefined>()

watch(
  () => modelValue.value?.from,
  (newFrom) => {
    if (!newFrom || !newFrom.month || !newFrom.year) {
      dateModelFrom.value = undefined
      return
    }
    const { month, year } = newFrom
    const date = dayjs(`${year}-${month + 1}-01`, 'YYYY-M-D').toDate()
    dateModelFrom.value = date
  },
  { immediate: false },
)

watch(
  () => modelValue.value?.to,
  (newTo) => {
    if (!newTo || !newTo.month || !newTo.year) {
      dateModelTo.value = undefined
      return
    }
    const { month, year } = newTo
    const date = dayjs(`${year}-${month + 1}-01`, 'YYYY-M-D').toDate()
    dateModelTo.value = date
  },
  { immediate: false },
)

const yearEnd = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
]
</script>

<template>
  <div
    v-if="initialFrom && initialTo"
    class="date-range-container flex justify-between transition-all duration-500"
    :style="{
      'padding-right': isModalOpen ? drawerWidth : undefined,
    }"
  >
    <div class="md:w-[500px]">
      <FormKit v-model="modelValue" type="group">
        <div class="flex gap-4">
          <FormKit
            label="Date Range Start"
            placeholder="Select start date"
            type="datepicker"
            outer-class="w-1/2"
            month-picker
            :min-date="props.forecastViewDateRange?.min ?? undefined"
            :max-date="dateModelTo"
            inner-class="!mb-0"
            :flow="['year', 'month']"
            name="from"
            format="MMM YYYY"
            :value="initialFrom"
            partial-flow
            auto-apply
            year-first
          >
          </FormKit>

          <FormKit
            :value="initialTo"
            label="Date Range End"
            placeholder="Select end date"
            type="datepicker"
            month-picker
            outer-class="w-1/2"
            :min-date="dateModelFrom"
            :max-date="props.forecastViewDateRange?.max ?? undefined"
            :flow="['year', 'month']"
            inner-class="!mb-0"
            format="MMM YYYY"
            name="to"
          />
        </div>
      </FormKit>
    </div>
    <FormKit
      v-if="type === ForecastReportType.ANNUAL"
      v-model="yearEnding"
      type="listbox"
      :options="yearEnd"
      outer-class="w-48 mr-2"
      inner-class="!mb-0"
      :value="yearEnd[5].value"
      label="Year ending"
      name="yearEnd"
    />
  </div>
  <div
    v-else
    class="date-range-container flex justify-between transition-all duration-500"
  >
    <div class="flex gap-4 md:w-[500px]">
      <div class="w-1/2">
        <span
          class="formkit-label mb-2 block text-sm font-medium text-gray-900 dark:text-white"
          for="input_5"
        >
          Date Range Start
        </span>
        <Skeleton class="h-10 w-full" />
      </div>
      <div class="w-1/2">
        <span
          class="formkit-label mb-2 block text-sm font-medium text-gray-900 dark:text-white"
          for="input_5"
        >
          Date Range End
        </span>
        <Skeleton class="h-10 w-full" />
      </div>
    </div>

    <div class="w-[190px]">
      <span
        class="formkit-label mb-2 block text-sm font-medium text-gray-900 dark:text-white"
        for="input_5"
      >
        Year ending
      </span>
      <Skeleton class="h-10 w-full" />
    </div>
  </div>
</template>

<style lang="postcss">
.date-range-container {
  .dp__overlay_col[aria-disabled='true'] {
    @apply hidden;
  }
}
</style>
